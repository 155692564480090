import React from "react";
import {Flex, Heading, Text} from "@radix-ui/themes";
import BarEntry from "./BarEntry";
import {STATIC_URL} from "../../api/config";

const wakeBeachBarImages = [
    {
        type: "htmlVideo",
        videoSrc: `${STATIC_URL}/images/wake_beach_bar/wake_beach_bar_video_old.mp4`,
        thumbnail: `${STATIC_URL}/images/wake_beach_bar/wake_beach_bar_hero_image.jpeg`,
        alt: ""
    },
    {
        src: `${STATIC_URL}/images/wake_beach_bar/WAKE_BEACH_BAR_GALLERY1.png`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/wake_beach_bar/WAKE_BEACH_BAR_GALLERY2.png`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/wake_beach_bar/WAKE_BEACH_BAR_GALLERY3.png`,
        alt: '',
    },
]

const camperBarImages = [
    {
        type: "htmlVideo",
        videoSrc: `${STATIC_URL}/images/camper_bar/camper_bar_video.mp4`,
        thumbnail: `${STATIC_URL}/images/camper_bar/CAMPER_BAR_GALLERY1.png`,
        alt: ""
    },
    {
        src: `${STATIC_URL}/images/camper_bar/CAMPER_BAR_GALLERY2.png`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/camper_bar/CAMPER_BAR_GALLERY3.png`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/camper_bar/bags_under_tent.jpg`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/camper_bar/drink_pour.jpg`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/camper_bar/kids_in_a_hammock.jpg`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/camper_bar/pile_of_stones.jpg`,
        alt: '',
    },
    {
        src: `${STATIC_URL}/images/camper_bar/camper_bar_front_view_hero_image.jpg`,
        alt: '',
    },
]

export default function BarsList() {
    return (
        <Flex direction='column' pt='4'>
            <Heading>Наші бари</Heading>

            <Flex mt='4' direction='column' gap='4'>
                <BarEntry
                    heroImage={`${STATIC_URL}/images/wake_beach_bar/WAKE_BEACH_BAR _ MAIN_PHOTO.png`}
                    galleryImages={wakeBeachBarImages}
                    name='Wake Beach Bar'
                    description="Атмосфера освіжаючих тропіків одразу на пляжі біля озера. Унікальні коктейлі від топових барменів, що точно вас здивують. І звичайно тут можна смачно підкріпитись. (Є дитячі позиції у меню)"
                    menuPDF={require('./menu-asstets/Wake_Bar_Menu.pdf')}
                    boldMessage="Бар зачинено до наступної весни."
                    disabled
                />
                <BarEntry
                    heroImage={`${STATIC_URL}/images/camper_bar/CAMPER_BAR_GALLERY1.png`}
                    galleryImages={camperBarImages}
                    name='Camper Bar'
                    description="Забудьте про міський шум, втечіть від повсякденної рутини і пірніть у світ унікальних коктейлів, смачного фастфуду. Насолоджуйтеся кіно просто неба — відпочинок і розваги гарантовані!"
                    menuPDF={require('./menu-asstets/Camper_Bar_menu.pdf')}
                    boldMessage="Бар зачинено до наступної весни."
                    disabled
                />
            </Flex>
        </Flex>
    )
}
